import React from 'react';

import what1 from '../../img/globe.svg'
import what2 from '../../img/data.svg'
import what3 from '../../img/lamp.svg'

import Fade from 'react-reveal/Fade'

function WhatDo() {
  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='flex flex-col xl:flex-row'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <div className='flex'>
                        <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0 max-w-[250px]'>·</h1>
                        <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-[10px] max-w-[250px]'>Why Partner with US? </h1>
                    </div>
                </div>
                <div className='xl:ml-[70px] xl:w-full mt-[40px] xl:mt-0 xl:mr-0 ml-[20px] mr-[20px]'>
                    <Fade bottom><div className='flex flex-col xl:flex-row'>
                        <div>
                            <img src={what1} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>Global Reach</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>Benefit from our extensive network and global perspective, ensuring you stay ahead in dynamic international markets.</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={300}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what2} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>Data-Driven Excellence</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>AVG International delivers accurate and timely data, enabling businesses to make informed decisions and navigate complex market landscapes.</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={600}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what3} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>Tailored Solutions</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>Our services are customizable to meet the unique needs of your business, ensuring a personalized and effective partnership.</p>
                        </div>
                    </div></Fade>
                </div>
            </div>
        </div>
    </>
  );
}

export default WhatDo;
