import React, { useState } from 'react';

import whatoffer1 from '../../img/whatoffer1.png'
import whatoffer2 from '../../img/whatoffer7.png'
import whatoffer3 from '../../img/Services/rectangle.png'
import whatoffer4 from '../../img/whatoffer8.png'
import whatoffer5 from '../../img/whatoffer6.png'
import whatoffer6 from '../../img/whatoffer5.png'
import whatoffer7 from '../../img/whatoffer10.png'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

import 'swiper/css';

import button from '../../img/button.svg'

const OfferData = [
    {
        id: 1,
        name: 'Natural gas and power distribution',
        about: 'AGT International specializes in delivering key data and insights into commodity markets that form the backbone of natural gas and electricity transmission and distribution systems. Our expertise helps optimize operations, manage risks, and enhance the efficiency of distribution networks.',
        image: whatoffer1
    },
    {
        id: 2,
        name: 'Mining',
        about: 'We offer an interconnected view of the metals, refined oil products, and energy markets that shape the global mining industries. AGT International`s services enable mining companies to understand market dynamics, manage operational challenges, and make informed investment decisions.',
        image: whatoffer2
    },
    {
        id: 3,
        name: 'Oil Refining and Distribution',
        about: 'Our integrated data and insights cover the entire oil refining and distribution supply chain. AGT International supports businesses from upstream exploration to downstream distribution, providing essential information for efficient operations and risk management.',
        image: whatoffer3
    },
    {
        id: 4,
        name: 'Textile Industry',
        about: 'Embark on a journey of textile excellence with AVG  International. From fashion and home decor to industrial applications, our collection embodies versatility and quality. Tailor your textile orders to your exact specifications. Whether you`re importing or exporting, we provide customizable solutions to meet the unique needs of your business. Colors, patterns, and specifications – we make it all possible.',
        image: whatoffer4
    },
    {
        id: 5,
        name: 'Power Generation',
        about: 'AGT International provides an interconnected view of electric power markets and the commodities driving them. Our services support power generation companies in understanding market trends, managing operational challenges, and optimizing portfolios for sustained success.',
        image: whatoffer5
    },
    {
        id: 6,
        name: 'Industrial manufacturing',
        about: 'From metals and polymers to fuel and waxes, we provide in-depth insights into the commodities that drive industrial manufacturing supply chains. AGT International empowers manufacturing companies to navigate market complexities, optimize resources, and make strategic decisions for sustained growth.',
        image: whatoffer6
    },
    {
        id: 7,
        name: 'Upstream Oil and Gas',
        about: 'From crude oil to LPG and NGLs, including natural gas and LNG, our coverage is vital in supporting upstream markets. AGT International provides crucial data on exploration, production, and transportation, empowering businesses in the upstream oil and gas sector to make informed decisions.',
        image: whatoffer7
    },
]

function WhatOffer() {
    const [swiper, setSwiper] = useState({});
    return (
        <>
          <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='flex xl:flex-row flex-col'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· What we offer?</h1>
                </div>
                <div className='xl:ml-auto'>
                    <div className='colorline h-[1px] w-[580px] xl:block hidden'></div>
                    <div className='flex'>
                        <div className='pagmain mt-[23px] ml-[20px] xl:ml-0'>
                            <div className='flex ml-[12px] mt-[3px]'>
                                <p className='inter text-[14px] font-light' id="currentSlideNumber">01</p>
                                <p className='inter text-[14px] textcolor font-light'>-07</p>
                            </div>
                        </div>
                        <div className='flex w-[80px] mt-[23px] ml-auto mr-[20px] xl:mr-0'>
                            <img
                            onClick={() => swiper.slidePrev()}
                            src={button}
                            className='w-[30px] h-[30px] rotate-180'
                            />
                            <img
                            onClick={() => swiper.slideNext()}
                            src={button}
                            className='w-[30px] h-[30px] ml-auto'
                            />
                        </div>
                    </div>
                </div>
            </div>
              <div className='mt-[20px] xl:mt-[40px]'>
                  <Swiper
                      modules={[Navigation]}
                      slidesPerView={1}
                      spaceBetween={10}
                      centeredSlides={true}
                      loop={true}
                      onInit={(e) => {
                          setSwiper(e);
                        }}
                      onSlideChange={(swiper) => {
                        const currentSlideNumber = document.getElementById("currentSlideNumber");
                            if (currentSlideNumber) {
                                currentSlideNumber.textContent = `0${swiper.realIndex + 1}`.slice(-2);
                            }
                        }}
                  >
                    {OfferData.map((slide, index) => (
                      <SwiperSlide key={slide.id}>
                          <div class="w-[335px] md:w-full mx-[20px] mx-auto h-auto xl:hidden block">
                              <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                  <img src={slide.image} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                              </div>
                              <h1 className='inter text-[16px] font-medium mt-[40px]'>{slide.name}</h1>
                              <p className='inter textcolor text-[12px] mt-[16px]'>{slide.about}</p>
                          </div>
                          <div className='w-[1280px] h-[305px] flex xl:flex hidden'>
                            <img src={slide.image} alt=""  className='w-[663px] object-cover'/>
                            <div className='ml-[40px]'>
                                <h1 className='inter text-[24px] font-medium'>{slide.name}</h1>
                                <p className='inter font-light textcolor text-[16px] mt-[12px] max-w-[497px]'>{slide.about}</p>
                            </div>
                          </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className='colorline h-[1px] w-[580px] xl:block hidden ml-auto'></div>
              </div>
          </div>
      </>
    );
  }
  
  export default WhatOffer;
  