import React from 'react';

import value1 from '../../img/ourvalues1.svg'
import value2 from '../../img/ourvalues3.svg'
import value3 from '../../img/ourvalues2.svg'
import value4 from '../../img/ourvalues4.svg'

import Fade from 'react-reveal/Fade'

function OurValues() {
  return (
    <>
      <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
        <div className='flex xl:flex-row flex-col'>
          <div>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· Our Values</h1>
          </div>
          <div className='flex flex-wrap xl:justify-end gap-[20px] mx-[20px] xl:mx-0 mt-[20px] xl:mt-0'>
            <Fade><div className='xl:w-[394px] w-full h-[180px] xl:h-[229px] border borderpeople'>
              <div className='mt-[20px] xl:ml-[40px] ml-[20px]'>
                <p className='font-light inter text-[24px]'>01</p>
                <div className='flex'>
                  <img src={value1} alt="" className='mt-[40px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] ml-[36px]'>Excellence</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={300}><div className='xl:w-[394px] w-full h-[180px] xl:h-[229px] border borderpeople'>
              <div className='mt-[20px] xl:ml-[40px] ml-[20px]'>
                <p className='font-light inter text-[24px]'>02</p>
                <div className='flex'>
                  <img src={value2} alt="" className='mt-[40px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] ml-[36px]'>Integrity</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={600}><div className='xl:w-[394px] w-full h-[180px] xl:h-[229px] border borderpeople'>
              <div className='mt-[20px] xl:ml-[40px] ml-[20px]'>
                <p className='font-light inter text-[24px]'>03</p>
                <div className='flex'>
                  <img src={value3} alt="" className='mt-[40px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] ml-[36px]'>Partnerships</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={900}><div className='xl:w-[394px] w-full h-[180px] xl:h-[229px] border borderpeople'>
              <div className='mt-[20px] xl:ml-[40px] ml-[20px]'>
                <p className='font-light inter text-[24px]'>04</p>
                <div className='flex'>
                  <img src={value4} alt="" className='mt-[40px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] ml-[36px]'>Inclusion</p>
                </div>
              </div>
            </div></Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurValues;
