import React, { useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import button from '../../img/button.svg'

import Fade from "react-reveal/Fade"

import 'swiper/css';

import feedback1 from '../../img/feedback1.png'
import feedback2 from '../../img/feedback2.png'
import feedback3 from '../../img/feedback3.png'
import feedback4 from '../../img/feedback4.png'
import feedback5 from '../../img/feedback5.png'

const feedbackData = [
    {
        id: 1,
        name: 'Sarah Bennett',
        feedback: 'Avad Gate Trading International has been a game-changer for my oil and gas trading ventures. The platform\'s specialized focus on this sector provides valuable insights, and their efficient services have significantly streamlined my import and export processes. Highly recommended for those in the energy trade.',
        image: feedback1
    },
    {
        id: 2,
        name: 'Richard Anderson',
        feedback: 'Working with AVG International has been a lucrative experience. Their expertise in oil and gas export/import is unmatched. The platform`s user-friendly interface simplifies complex transactions, and their professional team ensures a smooth journey through the intricacies of the energy market.',
        image: feedback2
    },
    {
        id: 3,
        name: 'Olivia Martinez',
        feedback: 'AVG has proven to be a reliable partner for my business. The platform`s commitment to excellence is evident in their seamless oil and gas trading solutions. The support team is responsive and knowledgeable, making it a go-to choice for anyone in the industry.',
        image: feedback3
    },
    {
        id: 4,
        name: 'Christopher Nguyen',
        feedback: 'Navigating the nuances of oil and gas trade became remarkably easier with Avad Gate Trading International. The platform`s comprehensive approach and tailored solutions have significantly boosted my trading success. Trustworthy, efficient, and a valuable asset for those venturing into the energy market.',
        image: feedback4
    },
    {
        id: 5,
        name: 'Jessica Taylor',
        feedback: 'Avad Gate Trading International has been instrumental in elevating my trading endeavors in the oil and gas sector. The platform`s global reach and industry insights have opened up new opportunities for import and export. I appreciate the transparent and professional approach they bring to the table. A solid choice for anyone in the energy trade.',
        image: feedback5
    },
];

function FeedBack() {
  const [swiper, setSwiper] = useState({});
  
  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto xl:pb-[180px] pb-[120px]'>
            <div className='flex'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· Feedback</h1>
                </div>
                <div className='flex w-[60px] ml-auto xl:mr-[20px] mr-[40px]'>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] ml-[20px]'
                    />
                </div>               
            </div>
            <div>
            <Swiper
                modules={[Navigation, Autoplay]}
                slidesPerView={2}
                spaceBetween={10}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                className='mt-[30px] xl:mt-[60px]'
                onInit={(e) => {
                    setSwiper(e);
                }}
                breakpoints={{
                    1: {
                        slidesPerView: 1,
                      },
                    680: {
                      slidesPerView: 2,
                    },
                  }}
            >
            {feedbackData.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <div className='xl:w-[635px] xl:h-[325px] h-[350px] border borderpeople mx-[20px] xl:mx-0'>
                        <img src={slide.image} alt="" className='mt-[20px] xl:ml-[40px] ml-[20px]'/>
                        <h1 className='inter text-[16px] xl:text-[20px] font-medium xl:ml-[40px] ml-[20px] mt-[20px]'>{slide.name}</h1>
                        <p className='text-[12px] xl:text-[16px] textcolor xl:ml-[40px] ml-[20px] mt-[12px] xl:mr-[50px]'>{slide.feedback}</p>
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </div>
        </div>
    </>
  );
}

export default FeedBack;
