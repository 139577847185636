import React, { useEffect } from 'react';

import Main from '../components/About/Main'
import AboutU from '../components/About/AboutUs'
import OurValues from '../components/Home/OurValues'
import OurPeople from '../components/About/OurPeople'
import FeedBack from '../components/Home/FeedBack'


import Lazyload from 'react-lazy-load'

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Main/>
      <Lazyload><AboutU/></Lazyload>
      <Lazyload><OurValues/></Lazyload>
      <Lazyload><OurPeople/></Lazyload>
      <Lazyload><FeedBack/></Lazyload>  
    </>
  );
}

export default About;
