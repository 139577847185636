import React from 'react';

import vetr from '../../img/Services/vetr.jpg'

function GlobalReach() {

  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='xl:absolute bg-white xl:w-[805px] h-[338px] mt-[171px]'>
                <div className='mt-[80px] mx-[20px] xl:mx-0'>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] xl:ml-0'>· Global Reach</h1>
                    <p className='max-w-[600px] font-light textcolor text-[16px] inter mt-[40px]'>At AGT International, we pride ourselves on our global reach and collaborative approach. Whether you are seeking to export or import natural gas, oil, or related products, our dedicated team is equipped to streamline the process, offering innovative solutions tailored to your specific requirements.</p>
                    <p className='max-w-[600px] font-light textcolor text-[16px] inter mt-[20px]'>Experience the synergy of efficiency and sustainability in energy trade – choose AGT International for your natural gas and oil export and import needs.</p>                
                </div>
            </div>
            <div className='xl:h-auto h-[406px] mt-[60px] xl:mt-0'>
                <img src={vetr} alt="" className='object-cover h-full w-full'/>
            </div>
        </div> 
    </>
  );
}

export default GlobalReach;
