import React from 'react';

import OurPeople1 from '../../img/About/man.jpg'
import OurPeople2 from '../../img/About/trube.jpg'

function OurPeople() {
  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[120px] xl:mt-[180px] relative'>
            <div className='flex xl:flex-row flex-col gap-[20px] mx-[20px] xl:mx-0'>
                <div className='xl:w-[630px] h-[334px] xl:h-[480px] borderpeople'>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[60px] xl:mt-[175px] ml-[20px]'>· About us</h1>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[520px] mt-[20px] xl:mt-[40px] mx-[20px] ml-[20px]'>No matter how precise and comprehensive, data on its own is insufficient. Our team, which combines in-depth knowledge of the commodities industry with a wealth of experience in journalism, market reporting, and strategic consulting, is our greatest asset.</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[520px] mt-[16px] xl:mt-[20px] mx-[20px] ml-[20px]'>Dedicated and committed to your needs, we pride ourselves on being accessible, responsive and connected to the markets.</p>
                </div>
                <div className='xl:w-[630px] h-[334px] xl:h-[480px] borderpeople overflow-hidden'>
                    <img src={OurPeople1} alt="" className='w-full h-full object-cover'/>
                </div>
            </div>
            <div className='flex xl:flex-row flex-col-reverse gap-[20px] mx-[20px] xl:mx-0 mt-[20px]'>
                <div className='xl:w-[630px] h-[334px] xl:h-[480px] borderpeople overflow-hidden'>
                    <img src={OurPeople2} alt="" className='w-full h-full object-cover'/>
                </div>
                <div className='xl:w-[630px] h-[334px] xl:h-[480px] borderpeople'>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[60px] xl:mt-[267px] ml-[20px]'>· Corporate social responsibility</h1>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[520px] mt-[20px] xl:mt-[40px] mx-[20px] ml-[20px]'>We take our responsibility to our community seriously. We believe in being good citizens, and in supporting organizations that help people in need in the areas we operate and beyond.</p>
                </div>
            </div>
        </div>
    </>
  );
}

export default OurPeople;
