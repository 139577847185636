import React from 'react';

import main from '../../img/About/PIC3(1).png'
import mainadapt from '../../img/About/adaptmain.png'
import Fade from 'react-reveal/Fade'

function Main() {
  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[80px] h-[680px] relative'>
            <div className='xl:absolute top-0 left-0 xl:h-full flex flex-col mx-[20px] xl:mx-0 z-10 relative'>
                <Fade bottom ><h1 className='neutral text-[32px] xl:text-[54px] xl:w-[827px] mt-[20px]'>Who we are</h1></Fade>
                <Fade bottom delay={300}><p className='inter text-black text-[16px] xl:text-[24px] xl:w-[722px] mt-[10px]'>Leading independent provider of global energy and commodity market intelligence</p></Fade>
                <Fade bottom delay={500}><div className='bg-black h-[1px] w-[178px] xl:w-[280px] mt-[60px]'></div></Fade>
                <Fade bottom delay={700}><h1 className='neutral text-[16px] xl:text-[24px] mt-[13px]'>· Our mission</h1></Fade>
                <Fade bottom delay={900}><p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[497px] mt-[15px]'>To shed light on opaque and complicated commodity markets. By means of precise and dependable price discovery and comprehensive analysis, we unveil the crucial insights that markets rely on to streamline decision-making and manage risk.</p></Fade>
            </div>
            <Fade bottom delay={500}><img src={main} alt="" className='w-full xl:block hidden'/></Fade>
            <Fade bottom delay={300}><div className='h-[94px] w-[200px] bg-white absolute z-10 xl:hidden'></div></Fade>
            <Fade bottom delay={500}><img src={mainadapt} alt="" className='xl:hidden h-[313px] block mt-[20px] w-[100%] object-cover'/></Fade>
        </div>
    </>
  );
}

export default Main;
