import React, { useState, useEffect } from "react";

import logo from '../img/avad.svg'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import '../components/header.css'

import { Link, useLocation } from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu'

function Header() {
  const languages = [
    { value: 'en', label: 'EN' },
    { value: 'ua', label: 'UA' },
    { value: 'ru', label: 'RU' },
  ];
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);

  };
  useEffect(() => {
    // Добавляем/удаляем класс 'menu-open' у body при открытии/закрытии меню
    if (menuOpen) {
      document.body.classList.add('menu-open');
      document.body.classList.remove('menu-closed');
    } else {
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    }

    return () => {
      // Удаляем класс при размонтировании компонента
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    };
  }, [menuOpen]);

  const [activePage, setActivePage] = useState('');
  const location = useLocation();

  React.useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);
  return (
      <>
        <header>
          <div className='flex h-[80px] max-w-[1280px] mx-auto hidden md:block'>
            <div className="flex gap-[40px] items-end mt-[15px] ml-[15px]">
              <Link to="/"><img src={logo} alt="" /></Link>
                <Dropdown
                  options={languages}
                  placeholder="EN"
                />
                <Link to="/services" className={`font-normal text-[16px] inter ${activePage === '/services' ? 'text-[#D14507]' : 'text-black'}`}>Services</Link>
                <Link to="/about" className={`font-normal text-[16px] inter ${activePage === '/about' ? 'text-[#D14507]' : 'text-black'}`}>About Us</Link>
            </div>
          </div>
          <div className="fixed top-0 z-50 bg-white w-full flex justify-between h-[60px] md:hidden">
            <div className="flex items-end ml-[15px]">
              <Link to="/"><img src={logo} alt="" /></Link>
            </div>
            <div className="flex mt-[25px] mr-[50px]">
              <Dropdown options={languages} placeholder="EN" className="mr-[30px]"/>
              <Menu customCrossIcon={
                <svg width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_85_293)">
                  <path d="M30.2835 8.11872L20.1648 18.2374L10.0461 8.11872L8.1187 10.0461L18.2374 20.1648L8.1187 30.2835L10.0461 32.2109L20.1648 22.0922L30.2835 32.2109L32.2108 30.2835L22.0921 20.1648L32.2108 10.0461L30.2835 8.11872Z" fill="black"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_85_293">
                  <rect width="50" height="50" fill="white"/>
                  </clipPath>
                  </defs>
                </svg> 
                } 
                width={'100%'} 
                right 
                isOpen={menuOpen} 
                onStateChange={handleStateChange}
              >
                <Link to="/"><img src={logo} alt="" className="absolute top-[12px] left-[20px]"/></Link>
                <Link to="/services" className='font-normal text-[24px] neutral text-black w-[140px] mx-auto mt-[80px]' onClick={closeMenu}>Services</Link>
                <Link to="/about" className='font-normal text-[24px] neutral text-black mt-[33px] w-[150px] mx-auto' onClick={closeMenu}>About Us</Link>
                
              </Menu>
            </div>
          </div>
        </header>
      </>
  );
}

export default Header;
