import React, { useState } from 'react';

import gas from '../../img/Services/gas.png'
import oil from '../../img/Services/oil.png'
import electro from '../../img/Services/electro.png'
import metal from '../../img/Services/metal.png'
import textiles from '../../img/Services/tectiles.png'

import gasa from '../../img/Services/adaptourproducts/gas.png'
import oila from '../../img/Services/adaptourproducts/oils.png'
import electroa from '../../img/Services/adaptourproducts/lamp.png'
import metala from '../../img/Services/adaptourproducts/metals.png'
import textilesa from '../../img/Services/adaptourproducts/textile.png'

import gassvg from '../../img/Services/gas.svg'
import oilsvg from '../../img/Services/can.svg'
import metalsvg from '../../img/Services/nut.svg'
import bulb from '../../img/Services/light-bulb.svg'
import flover from '../../img/Services/cotton.svg'

import Fade from 'react-reveal/Fade'

function OurProducts() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);

  const toggleSize = () => {
    setIsExpanded(true);
    setIsExpanded2(false);
    setIsExpanded3(false);
    setIsExpanded4(false);
    setIsExpanded5(false);
  };
  const toggleSize2 = () => {
    setIsExpanded2(true);
    setIsExpanded(false);
    setIsExpanded3(false);
    setIsExpanded4(false);
    setIsExpanded5(false);
  };
  const toggleSize3 = () => {
    setIsExpanded3(true);
    setIsExpanded2(false);
    setIsExpanded(false);
    setIsExpanded4(false);
    setIsExpanded5(false);
  };
  const toggleSize4 = () => {
    setIsExpanded3(false);
    setIsExpanded2(false);
    setIsExpanded(false);
    setIsExpanded4(true);
    setIsExpanded5(false);
  };
  const toggleSize5 = () => {
    setIsExpanded3(false);
    setIsExpanded2(false);
    setIsExpanded(false);
    setIsExpanded4(false);
    setIsExpanded5(true);
  };

  return (
    <>
          <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='flex xl:flex-row flex-col'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· Our Products</h1>
                </div>
                <div className='xl:ml-auto hidden xl:block'>
                    <p className='max-w-[497px] inter textcolor text-[16px] font-light'>Explore the world of energy with AGT International's comprehensive range of natural gas and oil solutions. Our expertise extends to facilitating seamless export and import operations, ensuring a reliable and efficient supply chain.</p>
                </div>
            </div>
            <div className='flex xl:flex-row flex-col mt-[50px] gap-[20px]'>
                <div>
                    <Fade duration={1000}><div className={`xl:w-[761px] mx-[20px] xl:mx-0 h-[360px] xl:h-[480px] border borderpeople ${ isExpanded ? 'block' : 'hidden'}`}>
                        <img src={gassvg} alt="" className='w-[40px] h-[40px] xl:w-[60px] xl:h-[60px] mt-[30px] xl:mt-[45px] ml-[30px] xl:ml-[60px]'/>
                        <h1 className='inter text-[24px] font-medium ml-[30px] xl:ml-[60px] mt-[40px] xl:mt-[80px]'>Natural Gas</h1>
                        <p className='max-w-[558px] text-[12px] xl:text-[16px] font-light inter textcolor ml-[30px] mr-[30px] xl:mr-0 xl:ml-[60px] mt-[12px]'>Unlock the potential of natural gas with AGT International. We specialize in facilitating the export and import of natural gas, offering strategic solutions to meet the evolving energy needs of our global partners. From sourcing to delivery, our commitment to excellence ensures a smooth and sustainable supply of natural gas.</p>
                    </div></Fade>
                    <div className={`hidden xl:block relative w-[110px] xl:h-[480px] ${isExpanded ? 'xl:hidden' : 'block'}`} onClick={toggleSize}>
                        <img src={gas} alt="" />
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <span className="text-[24px] inter font-medium transform -rotate-90 mb-[100px] whitespace-nowrap text-white">Natural Gas</span>
                        </div>
                    </div>
                    <div className={`block xl:hidden relative h-[77px] mx-[20px] ${ isExpanded ? 'hidden' : 'block'}`} onClick={toggleSize}>
                        <img src={gasa} alt="" className='w-full h-full'/>
                        <div className="absolute top-[25px] left-[40px] flex justify-center">
                            <span className="text-[20px] inter font-medium transform mb-[50px] whitespace-nowrap text-white">Natural Gas</span>
                        </div>
                    </div>
                </div>
                <div>
                    <Fade duration={1000}><div className={`xl:w-[761px] mx-[20px] xl:mx-0 h-[360px] xl:h-[480px] border borderpeople ${ isExpanded2 ? 'block' : 'hidden'}`}>
                        <img src={oilsvg} alt="" className='w-[40px] h-[40px] xl:w-[60px] xl:h-[60px] mt-[30px] xl:mt-[45px] ml-[30px] xl:ml-[60px]'/>
                        <h1 className='inter text-[24px] font-medium ml-[30px] xl:ml-[60px] mt-[40px] xl:mt-[80px]'>Oil</h1>
                        <p className='max-w-[558px] text-[12px] xl:text-[16px] font-light inter textcolor ml-[30px] mr-[30px] xl:mr-0 xl:ml-[60px] mt-[12px]'>Dive into the dynamic realm of oil trade with AGT International. We excel in managing the export and import processes of various oil products, providing a strategic advantage to our clients in the energy sector. Our network and industry insights enable us to navigate the complexities of the oil market, ensuring reliability and value in every transaction.</p>
                    </div></Fade>
                    <div className={`hidden xl:block relative w-[110px] h-[480px] ${ isExpanded2 ? 'xl:hidden' : 'block'}`} onClick={toggleSize2}>
                        <img src={oil} alt="" />
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <span className="text-[24px] inter font-medium transform -rotate-90 mb-[50px] whitespace-nowrap text-white">Oil</span>
                        </div>
                    </div>
                    <div className={`block xl:hidden relative h-[77px] mx-[20px] ${ isExpanded2 ? 'hidden' : 'block'}`} onClick={toggleSize2}>
                        <img src={oila} alt="" className='w-full h-full'/>
                        <div className="absolute top-[25px] left-[40px] flex justify-center">
                            <span className="text-[20px] inter font-medium transform mb-[50px] whitespace-nowrap text-white">Oil</span>
                        </div>
                    </div>
                </div>
                <div>
                    <Fade duration={1000}><div className={`xl:w-[761px] mx-[20px] xl:mx-0 h-[360px] xl:h-[480px] border borderpeople ${ isExpanded3 ? 'block' : 'hidden'}`}>
                        <img src={bulb} alt="" className='w-[40px] h-[40px] xl:w-[60px] xl:h-[60px] mt-[30px] xl:mt-[45px] ml-[30px] xl:ml-[60px]'/>
                        <h1 className='inter text-[24px] font-medium ml-[30px] xl:ml-[60px] mt-[40px] xl:mt-[80px]'>Electric Energy</h1>
                        <p className='max-w-[558px] text-[12px] xl:text-[16px] font-light inter textcolor ml-[30px] mr-[30px] xl:mr-0 xl:ml-[60px] mt-[12px]'>Empower your business with AGT International’s electric energy export and import services. We specialize in facilitating the seamless exchange of electric energy, ensuring a reliable and efficient supply chain. From renewable sources to conventional power, our strategic solutions cater to diverse energy needs, promoting a greener and more sustainable future.</p>
                    </div></Fade>
                    <div className={`hidden xl:block relative w-[110px] h-[480px] ${ isExpanded3 ? 'xl:hidden' : 'block'}`} onClick={toggleSize3}>
                        <img src={electro} alt="" />
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <span className="text-[24px] inter font-medium transform -rotate-90 mb-[120px] whitespace-nowrap text-white">Electric Energy</span>
                        </div>
                    </div>
                    <div className={`block xl:hidden relative h-[77px] mx-[20px] ${ isExpanded3 ? 'hidden' : 'block'}`} onClick={toggleSize3}>
                        <img src={electroa} alt="" className='w-full h-full'/>
                        <div className="absolute top-[25px] left-[40px] flex justify-center">
                            <span className="text-[20px] inter font-medium transform mb-[50px] whitespace-nowrap text-white">Electric Energy</span>
                        </div>
                    </div>
                </div>
                <div>
                    <Fade duration={1000}><div className={`xl:w-[761px] mx-[20px] xl:mx-0 h-[360px] xl:h-[480px] border borderpeople ${ isExpanded4 ? 'block' : 'hidden'}`}>
                        <img src={metalsvg} alt="" className='w-[40px] h-[40px] xl:w-[60px] xl:h-[60px] mt-[30px] xl:mt-[45px] ml-[30px] xl:ml-[60px]'/>
                        <h1 className='inter text-[24px] font-medium ml-[30px] xl:ml-[60px] mt-[40px] xl:mt-[80px]'>Metals</h1>
                        <p className='max-w-[558px] text-[12px] xl:text-[16px] font-light inter textcolor ml-[30px] mr-[30px] xl:mr-0 xl:ml-[60px] mt-[12px]'>Forge a path to success in the metals industry with AGT International. We excel in managing the export and import processes of various metals, providing a strategic advantage to our clients in this dynamic sector. From raw materials to finished products, our global network and industry insights enable us to optimize the supply chain, delivering value and reliability.</p>
                    </div></Fade>
                    <div className={`hidden xl:block relative w-[110px] h-[480px] ${ isExpanded4 ? 'xl:hidden' : 'block'}`} onClick={toggleSize4}>
                        <img src={metal} alt="" />
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <span className="text-[24px] inter font-medium transform -rotate-90 mb-[75px] whitespace-nowrap text-white">Metals</span>
                        </div>
                    </div>
                    <div className={`block xl:hidden relative h-[77px] mx-[20px] ${ isExpanded4 ? 'hidden' : 'block'}`} onClick={toggleSize4}>
                        <img src={metala} alt="" className='w-full h-full'/>
                        <div className="absolute top-[25px] left-[40px] flex justify-center">
                            <span className="text-[20px] inter font-medium transform mb-[50px] whitespace-nowrap text-white">Metals</span>
                        </div>
                    </div>
                </div>
                <div>
                    <Fade duration={1000}><div className={`xl:w-[761px] mx-[20px] xl:mx-0 h-[360px] xl:h-[480px] border borderpeople ${ isExpanded5 ? 'block' : 'hidden'}`}>
                        <img src={flover} alt="" className='w-[40px] h-[40px] xl:w-[60px] xl:h-[60px] mt-[30px] xl:mt-[45px] ml-[30px] xl:ml-[60px]'/>
                        <h1 className='inter text-[24px] font-medium ml-[30px] xl:ml-[60px] mt-[40px] xl:mt-[80px]'>Textiles</h1>
                        <p className='max-w-[558px] text-[12px] xl:text-[16px] font-light inter textcolor ml-[30px] mr-[30px] xl:mr-0 xl:ml-[60px] mt-[12px]'>At Avad Gate, we specialize in the import and export of premium textiles, bringing a world of quality and craftsmanship to your fingertips. Our Textile Industry Section is a testament to our commitment to delivering top-notch fabrics to clients worldwide.  Avad Gate ensures that you receive textiles of exceptional quality, reflective of our dedication to excellence.</p>
                    </div></Fade>
                    <div className={`hidden xl:block relative w-[110px] h-[480px] ${ isExpanded5 ? 'xl:hidden' : 'xl:block'}`} onClick={toggleSize5}>
                        <img src={textiles} alt="" />
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <span className="text-[24px] inter font-medium transform -rotate-90 mb-[80px] whitespace-nowrap text-white">Textiles</span>
                        </div>
                    </div>
                    <div className={`block xl:hidden relative h-[77px] mx-[20px] ${ isExpanded5 ? 'hidden' : 'block'}`} onClick={toggleSize5}>
                        <img src={textilesa} alt="" className='w-full h-full'/>
                        <div className="absolute top-[25px] left-[40px] flex justify-center">
                            <span className="text-[20px] inter font-medium transform mb-[50px] whitespace-nowrap text-white">Textiles</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default OurProducts;
