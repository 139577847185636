import React from 'react';

import main from '../../img/Services/main.png'
import mainadapt from '../../img/mainpng.png'
import Fade from 'react-reveal/Fade'

function Main() {
  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[80px] h-[680px] relative'>
            <div className='xl:absolute top-0 left-0 xl:h-full flex flex-col mx-[20px] xl:mx-0 z-10 relative'>
                <Fade bottom delay={200}><p className='inter text-[16px] xl:text-[24px] xl:mt-[17px]'>Avad Gate Trading International</p></Fade>
                <Fade bottom delay={500}><h1 className='neutral text-[32px] xl:text-[54px] xl:w-[827px] mt-[20px]'>Comprehensive Energy Solutions</h1></Fade>
                <Fade bottom delay={800}><p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[497px] mt-[20px] xl:mt-[50px]'>We offer a diverse range of import and export services encompassing the entire energy sector. Our expertise extends across various segments, providing key data and insights crucial for informed decision-making in the following areas.</p></Fade>
            </div>
            <Fade bottom delay={500}><img src={main} alt="" className='w-full xl:block hidden'/></Fade>
            <Fade bottom delay={300}><div className='h-[94px] w-[200px] bg-white absolute z-10 xl:hidden'></div></Fade>
            <Fade bottom delay={500}><img src={mainadapt} alt="" className='xl:hidden h-[313px] block mt-[20px] w-[100%] object-cover'/></Fade>
        </div>
    </>
  );
}

export default Main;
