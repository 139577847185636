import React from 'react';

import about1 from '../../img/About/about1.jpg'
import about2 from '../../img/About/about2.jpg'

import Fade from 'react-reveal/Fade'

function AboutUs() {
  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[120px] xl:mt-[180px] relative'>
            <div className='flex flex-col xl:flex-row'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· About us</h1>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-0'>We offer essential price assessments, analytics, consulting services, data science tools and industry conferences to illuminate complex and opaque commodity markets.</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[16px] xl:mt-[20px] mx-[20px] xl:mx-0'>Our independent organization has over 1, 000 experts spread across 30 offices in the world`s key commodity trading hubs, with its headquarters in London.</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[16px] xl:mt-[20px] mx-[20px] xl:mx-0'>Founded in 2017, AGT International remains a privately held UK-registered company owned by employee shareholders and global growth equity firm General Atlantic technology services investor.</p>
                </div>
                <Fade bottom><div className='xl:ml-[120px] mt-[40px] xl:mt-0'>
                    <img src={about1} className='w-[335px] mx-auto xl:mx-0 xl:w-[600px] xl:h-[649px]' alt="" />
                </div></Fade>
            </div>
            <div className='flex flex-col-reverse xl:flex-row'>
                <Fade bottom delay={200}><div className='mx-[20px] xl:mx-0 xl:w-[600px] xl:h-[654px]'>
                    <img src={about2} alt="" className='xl:w-[600px] xl:h-[654px] xl:absolute xl:bottom-[100px] mt-[40px] xl:mt-0'/>
                </div></Fade>
                <div className='xl:ml-[120px] mx-[20px] xl:mx-0'>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[80px] xl:mt-[160px]'>AGT prices are used as trusted benchmarks around the world for pricing transportation, commodities & energy.</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[16px] xl:mt-[20px]'>Our data is used by businesses, governments, and trading companies in 160 countries to help with decision-making, situation analysis, risk management, trading, and long-term planning.</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[16px] xl:mt-[20px]'>Our consistent editorial independence, reliable processes, and cooperative customer-focused approach have guaranteed them an accurate perspective. As a result, they have a deeper comprehension of the market dynamics that they can use to manage risk and make more informed decisions.</p>
                </div>
            </div>
        </div>
    </>
  );
}

export default AboutUs;
