import React, { useEffect, useState } from "react";

import phone from '../img/phone.svg'
import location from '../img/location.svg'
import eemail from '../img/email.svg'

import axios from "axios";

function Footer() {
    const [ name, setName ] = useState('');
    const [ telephone, setTelephone ] = useState('');
    const [ email, setEmail ] = useState('');

    const [ nameValid, setNameValid ] = useState(true);
    const [ telephoneValid, setTelephoneValid ] = useState(true);
    const [ emailValid, setEmailValid ] = useState(true);

    const clearInputs = () => {
      setName('');
      setTelephone('');
      setEmail('');
      setNameValid(true);
      setTelephoneValid(true);
      setEmailValid(true);
    }
    const sendTgMsg = async () => {
      const messageData = {
          name,
          telephone,
          email,
      };
      clearInputs();
      try {
          const result = await axios.post('https://civbt.xyz/api/bot/NnGRVtdnJU', messageData);
          if (result.status === 200) {
              return true;
          } else {
              alert('Something went wrong. Try again');
              return false;
          }
      } catch (error) {
          if (error.response.data.status === 403) {
              alert(error.response.data.message);
              return;
          }
          alert(error.message);
      }
    }
    const checkName = () => {
      if (name.length > 0) {
          setNameValid(true)
          return true;
      }
      setNameValid(false);
      return false;
    }
    const checkPhone = () => {
      if (/^\d{10,}$/.test(telephone)) {
            setTelephoneValid(true);
            return true;
        }
        setTelephoneValid(false);
        return false;
    }
    const checkEmail = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (expression.test(String(email.toLocaleLowerCase()))) {
            setEmailValid(true)
            return true;
        }
        setEmailValid(false);
        return false;
    }
    const submit = () => {
      if (
          checkName() &&
          checkPhone() &&
          checkEmail()
      ) {
          sendTgMsg();
      }
    }
  return (
    <>
      <div className='max-w-[1280px] mx-auto'>
        <div className='flex xl:flex-row flex-col pb-[140px]'>
          <div>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· Contact us</h1>
            <p className='mt-[20px] max-w-[497px] font-light text-[12px] xl:text-[16px] textcolor mx-[20px] xl:mx-0'>Fill out the form and we will tailor a solution to meet your needs. After a consultation with one of our experts, you can get a free trial of AGT services.</p>
            <div className='mt-[30px] flex flex-col mx-[20px] xl:mx-0'>
              <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' type="text" className='formfooter xl:w-[393px] h-[52px]'/>
              <input value={telephone} onChange={(e) => setTelephone(e.target.value)} placeholder='Tel' type="text" className='formfooter xl:w-[393px] h-[52px] mt-[12px]'/>
              <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-Mail' type="text" className='formfooter xl:w-[393px] h-[52px] mt-[12px]'/>
              <div className='flex mt-[20px]'>
                <input type="checkbox" className='w-[18px] h-[18px] mt-[8px]'/>
                <p className='max-w-[349px] font-light text-[12px] textcolor ml-[26px]'>We will treat your data with respect and use it only in accordance with our Privacy Policy.</p>
              </div>
              <div className='buttonfooter mt-[40px]' onClick={submit}>
                <p className='text-white inter text-[20px]'>Submit</p>
              </div>
            </div>
          </div>
          <div className='xl:ml-auto mt-[120px] xl:mt-0'>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· Contact Info</h1>
            <div className='mt-[40px] mx-[20px] xl:mx-0'>
              <div className='flex'>
                <img src={phone} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>Phone</p>
              </div>
              <div className='flex mt-[10px]'>
                <img src={location} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>Address</p>
              </div>
              <div className='flex mt-[10px]'>
                <img src={eemail} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>E-Mail</p>
              </div>
            </div>
            <div className='mt-[60px] mx-[20px] xl:mx-0'>
              <h1 className='inter text-[20px] font-medium'>Business Hours</h1>
              <p className='textcolor mt-[30px] text-[12px] xl:text-[16px] font-light'>• We work 6 days a week</p>
              <p className='textcolor text-[12px] xl:text-[16px] mt-[5px] font-light'>• Sun - Wed : 08:00 am - 05.30 pm</p>
              <p className='textcolor text-[12px] xl:text-[16px] mt-[5px] font-light'>• Thur - Fr : 08:00 am - 02:00 pm</p>
              <p className='textcolor text-[12px] xl:text-[16px] mt-[5px] font-light'>• Saturday : 08:00 am - 05:30 pm</p>
              <p className='textcolor text-[12px] xl:text-[16px] mt-[5px] font-light'>• Every Sunday  is  holiday</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
