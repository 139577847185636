import React, {useState, useEffect} from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header'
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import {images} from './imagesToLoad'

import $ from 'jquery'

function App() {
  const [ isLoading, setIsLoading ] = useState(true);
  
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const newImage = new Image();
        newImage.src = image;
        newImage.onload = () => {
          resolve(image);
        }
        newImage.onerror = (err) => reject(err);
      });
    };
  
    useEffect(() => {
      isLoading && $('body').addClass("ovf-hidden");
      Promise
        .all(images.map((image) => loadImage(image)))
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.log("Failed to load images", err)
          setIsLoading(false);
        });
    }, []);
  
    useEffect(() => {
      !isLoading && $('body').removeClass("ovf-hidden");
      !isLoading && $('#loader').addClass("hide");
    }, [isLoading]);
  
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
        </Routes>
      <Footer />
  </Router>
  );
}

export default App;
