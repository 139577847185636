import React, { useEffect } from 'react';

import Main from '../components/Services/Main'
import WhatOffer from '../components/Services/WhatOffer'
import WhyPartner from '../components/Services/WhyPartner'
import OurProducts from '../components/Services/OurProducts'
import GlobalReach from '../components/Services/GlobalReach'
import OurAdvantages from '../components/Services/OurAdvantages'

import Lazyload from 'react-lazy-load'

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Main/>
      <Lazyload><WhatOffer/></Lazyload>
      <Lazyload><WhyPartner/></Lazyload>
      <Lazyload><OurProducts/></Lazyload>
      <Lazyload><GlobalReach/></Lazyload>
      <Lazyload><OurAdvantages/></Lazyload>   
    </>
  );
}

export default Services;
